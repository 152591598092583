import React, { useState } from 'react';
import { ArrowRight, Menu, X, Lightbulb, TrendingUp, Shield, DollarSign, BarChart2, Smartphone } from 'lucide-react';

const NavLink = ({ href, children }) => (
  <a href={href} className="text-gray-600 hover:text-blue-600 transition-colors duration-300">{children}</a>
);

const Feature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-start p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
    <Icon className="w-10 h-10 text-blue-500 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const CappyAIWebsite = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const faqData = [
    {
      question: "How does CappyAI's AI technology work?",
      answer: "CappyAI uses advanced machine learning algorithms to analyze market data, your portfolio, and various economic indicators. It then generates personalized insights and recommendations based on your investment goals and risk tolerance."
    },
    {
      question: "Is my financial data secure with CappyAI?",
      answer: "Absolutely. We use bank-level encryption and security measures to protect your data. We also adhere to strict privacy policies and never sell your personal information."
    },
    {
      question: "Can I use CappyAI if I'm new to investing?",
      answer: "Yes! CappyAI is designed for investors of all experience levels. Our user-friendly interface and educational resources make it easy for beginners to get started, while our advanced features cater to more experienced investors."
    },
    {
      question: "How often are the AI insights updated?",
      answer: "Our AI continuously analyzes market data and updates insights in real-time. You'll receive notifications for significant changes or recommendations that require your attention."
    },
    {
      question: "What types of investments does CappyAI support?",
      answer: "CappyAI supports a wide range of investment types including stocks, bonds, ETFs, mutual funds, and cryptocurrencies. Our AI adapts to various investment strategies and asset classes."
    },
    {
      question: "Can I integrate CappyAI with my existing brokerage account?",
      answer: "Yes, CappyAI integrates with most major brokerage platforms. We're constantly expanding our list of supported brokers. Check our integration page for the most up-to-date list of compatible brokers."
    },
    {
      question: "What if I'm not satisfied with CappyAI's service?",
      answer: "We offer a 30-day money-back guarantee. If you're not completely satisfied with CappyAI, simply contact our support team within 30 days of your purchase for a full refund."
    },
    {
      question: "Does CappyAI provide customer support?",
      answer: "Absolutely! We offer 24/7 customer support via email and chat. Our Premium and Enterprise plans also include dedicated phone support during business hours."
    }
  ];

  const testimonialData = [
    {
      name: "Sarah Johnson",
      role: "Individual Investor",
      image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=150&h=150&q=80",
      quote: "CappyAI has transformed the way I manage my investments. The AI-driven insights have helped me make smarter decisions and significantly improve my returns."
    },
    {
      name: "Michael Chen",
      role: "Financial Advisor",
      image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=150&h=150&q=80",
      quote: "As a financial advisor, I've found CappyAI to be an invaluable tool. It helps me provide more data-driven recommendations to my clients and stay ahead of market trends."
    },
    {
      name: "Emily Rodriguez",
      role: "Startup Founder",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=150&h=150&q=80",
      quote: "The tax optimization feature in CappyAI has been a game-changer for my personal investments. It's helped me maximize my after-tax returns in ways I never thought possible."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-white shadow-sm sticky top-0 z-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <span className="text-2xl font-bold text-blue-600">CappyAI</span>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <span className="sr-only">Open menu</span>
                {mobileMenuOpen ? <X className="h-6 w-6" aria-hidden="true" /> : <Menu className="h-6 w-6" aria-hidden="true" />}
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              <NavLink href="#features">Features</NavLink>
              <NavLink href="#how-it-works">How It Works</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
              <NavLink href="#testimonials">Testimonials</NavLink>
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                Sign in
              </a>
              <a
                href="#"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Start free trial
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        {/* Hero Section */}
        <section className="relative h-screen flex items-center justify-center overflow-hidden">
          {/* Background Video */}
          <video 
            autoPlay 
            loop 
            muted 
            playsInline
            className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
          >
            <source src="/assets/videos/cappy-ai-trader-analysis.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {/* Overlay */}
          <div className="absolute z-20 w-full h-full bg-black opacity-60"></div>

          {/* Content */}
          <div className="relative z-30 text-center text-white px-4 max-w-4xl">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold leading-tight mb-4">
              Revolutionize Your Investments with AI-Powered Insights
            </h1>
            <p className="text-xl sm:text-2xl md:text-3xl mb-8">
              Harness the power of artificial intelligence to optimize your portfolio, minimize risks, and maximize returns.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <a
                href="#"
                className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:text-lg transition duration-150 ease-in-out"
              >
                Start Your Journey
                <ArrowRight className="ml-2 -mr-1 w-5 h-5" />
              </a>
              <a
                href="#"
                className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-100 md:text-lg transition duration-150 ease-in-out"
              >
                Watch Demo
              </a>
            </div>
          </div>

          {/* Scroll Indicator */}
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 z-30">
            <a href="#features" className="text-white animate-bounce">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            </a>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="py-20 bg-gray-50">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Powerful Features for Smart Investing
              </h2>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                CappyAI combines advanced technologies to give you a competitive edge in the market.
              </p>
            </div>

            <div className="mt-20 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
              <Feature
                icon={Lightbulb}
                title="AI-Driven Insights"
                description="Receive personalized investment recommendations based on cutting-edge machine learning algorithms."
              />
              <Feature
                icon={TrendingUp}
                title="Performance Tracking"
                description="Monitor your portfolio's performance with intuitive charts and real-time metrics."
              />
              <Feature
                icon={Shield}
                title="Risk Assessment"
                description="Understand and mitigate potential risks with our advanced risk analysis tools."
              />
              <Feature
                icon={DollarSign}
                title="Tax Optimization"
                description="Maximize your after-tax returns with intelligent tax-loss harvesting strategies."
              />
              <Feature
                icon={BarChart2}
                title="Market Analysis"
                description="Stay ahead of market trends with our comprehensive market analysis and forecasting."
              />
              <Feature
                icon={Smartphone}
                title="Mobile Access"
                description="Manage your investments on-the-go with our fully responsive mobile app."
              />
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section id="how-it-works" className="py-20 bg-white">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                How CappyAI Works
              </h2>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Experience the power of AI-driven investing in three simple steps.
              </p>
            </div>

            <div className="mt-20">
              <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-between">
                  {[
                    { step: '01', title: 'Connect Your Accounts' },
                    { step: '02', title: 'AI Analysis' },
                    { step: '03', title: 'Optimize & Grow' },
                  ].map((item) => (
                    <div key={item.step} className="bg-white px-4">
                      <span className="flex h-12 w-12 items-center justify-center rounded-full border-2 border-blue-600 text-blue-600">
                        {item.step}
                      </span>
                      <p className="mt-2 text-center font-semibold text-gray-900">{item.title}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-3">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">1. Connect Your Accounts</h3>
                  <p className="mt-2 text-base text-gray-500">
                    Securely link your investment accounts or manually input your portfolio details.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">2. AI Analysis</h3>
                  <p className="mt-2 text-base text-gray-500">
                    Our advanced AI algorithms analyze your portfolio and current market conditions.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">3. Optimize & Grow</h3>
                  <p className="mt-2 text-base text-gray-500">
                    Receive personalized recommendations and execute trades to optimize your portfolio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="py-20 bg-gray-50">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Pricing Plans
              </h2>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Choose the plan that fits your investment strategy.
              </p>
            </div>

            <div className="mt-16 grid gap-8 lg:grid-cols-3">
              {[
                { name: 'Starter', price: '$19', features: ['Basic AI insights', 'Portfolio tracking', 'Email support'] },
                { name: 'Pro', price: '$49', features: ['Advanced AI recommendations', 'Tax optimization', 'Priority support'] },
                { name: 'Enterprise', price: 'Custom', features: ['Full-suite AI tools', 'Dedicated account manager', 'Custom integrations'] },
              ].map((plan) => (
                <div key={plan.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
                    <div className="mt-4 flex items-baseline">
                      <span className="text-4xl font-extrabold text-gray-900">{plan.price}</span>
                      <span className="ml-1 text-xl font-semibold text-gray-500">/month</span>
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      {plan.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-8">
                      <a
                        href="#"
                        className="block w-full bg-blue-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
                      >
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials" className="py-20 bg-white">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                What Our Clients Say
              </h2>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Don't just take our word for it. Here's what investors like you have to say about CappyAI.
              </p>
            </div>
            <div className="mt-16 grid gap-8 lg:grid-cols-3">
              {testimonialData.map((testimonial, index) => (
                <div key={index} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-xl font-semibold text-gray-900">{testimonial.name}</p>
                      <p className="mt-1 text-base text-gray-500">{testimonial.role}</p>
                      <p className="mt-3 text-base text-gray-500">"{testimonial.quote}"</p>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={testimonial.image} alt={`${testimonial.name}'s profile`} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-20 bg-gray-50">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Frequently Asked Questions
              </h2>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Got questions? We've got answers.
              </p>
            </div>
            <div className="mt-12 max-w-lg mx-auto divide-y-2 divide-gray-200">
              {faqData.map((faq, index) => (
                <div key={index} className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
                  <dt className="text-base font-medium text-gray-900 md:col-span-5">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 md:mt-0 md:col-span-7">
                    <p className="text-base text-gray-500">{faq.answer}</p>
                  </dd>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="bg-blue-700">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
              <span className="block">Ready to revolutionize your investments?</span>
              <span className="block text-blue-200">Start your free trial today.</span>
            </h2>
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="#"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50"
                >
                  Get started
                </a>
              </div>
              <div className="ml-3 inline-flex rounded-md shadow">
                <a
                  href="#"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <div className="relative w-20 h-20 overflow-hidden shadow-lg rounded-lg">
                <img 
                  src="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/sulqucsfnvx5pn3pabij"
                  alt="CappyAI Logo"
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-gray-400 text-base">
                Empowering investors with AI-driven insights and portfolio optimization.
              </p>
              <div className="flex space-x-6">
                {/* Add social media icons here */}
              </div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Solutions</h3>
                  <ul className="mt-4 space-y-4">
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">AI Insights</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Portfolio Optimization</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Tax Strategies</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Risk Management</a></li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Support</h3>
                  <ul className="mt-4 space-y-4">
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Pricing</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Documentation</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Guides</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">API Status</a></li>
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                  <ul className="mt-4 space-y-4">
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">About</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Blog</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Jobs</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Press</a></li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
                  <ul className="mt-4 space-y-4">
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Privacy</a></li>
                    <li><a href="#" className="text-base text-gray-300 hover:text-white">Terms</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-700 pt-8">
            <p className="text-base text-gray-400 xl:text-center">
              &copy; 2024 CappyAI, Ltd. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CappyAIWebsite;