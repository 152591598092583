import './App.css';
import CappyAIWebsite from './components/CappyAIWebsite';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <div className="App">
      <CappyAIWebsite />
      <SpeedInsights />
      <Analytics />
    </div>
  );
}

export default App;